<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <b-modal
      class="p-2"
      id="export-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-modal')"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto" style="font-size: 18px">
        {{ $t("asset.choose_ex") }}
      </p>
      <b-row align-h="center">
        <!-- <button class="px-2 email-me btn-auto" @click="onExport(true)">
          {{ $t("btn.send_mail") }}
        </button> -->

        <button class="px-2 download-file" @click="onExport(false)">
          {{ $t("btn.dl_file") }}
        </button>
      </b-row>
    </b-modal>
    <b-modal
      id="filter-modal"
      @cancel="handleclear"
      :cancel-title="$t('btn.clear')"
      hide-header-close
      hide-header
      :ok-title="$t('btn.conf')"
      @ok="handleFilter"
    >
      <b-row>
        <b-col class="text-end" cols="2"> </b-col>
        <b-col class="text-center" cols="8">
          <h3 class="text-dark">{{ $t("filter.dt_fil") }}</h3>
        </b-col>
        <b-col class="text-end" cols="2">
          <button
            class="close_modal text-light border-none"
            @click="$bvModal.hide('filter-modal')"
          >
            <b-icon-x scale="1.5" />
          </button>
        </b-col>
      </b-row>
      <label>{{ $t("maintenance_plan.maintenance_range") }}</label>
      <div class="row align-items-center">
        <date-picker
          class="inline-block h-full col-6"
          v-model="filterOptions.start_date"
          locale="en"
        >
          <template v-slot="{ inputValue, togglePopover }">
            <div class="row">
              <div class="col">
                <input
                  style="cursor: pointer;"
                  :value="inputValue | formatDate"
                  class="form-control px-20"
                  readonly
                  @click="togglePopover()"
                />
              </div>
            </div>
          </template>
        </date-picker>
        -
        <date-picker
          class="inline-block h-full col"
          v-model="filterOptions.end_date"
          locale="en"
          :min-date="filterOptions.start_date"
        >
          <template v-slot="{ inputValue, togglePopover }">
            <div class="row">
              <div class="col-12">
                <input
                  style="cursor: pointer;"
                  :value="inputValue | formatDate"
                  class="form-control px-20"
                  readonly
                  @click="togglePopover()"
                />
              </div>
            </div>
          </template>
        </date-picker>
      </div>
      <label class="mt-2">{{ $t("maintenance_plan.vendor_in_charge") }}</label>
      <v-select
        :options="optionsVendor"
        v-model="filterOptions.vendor"
        :reduce="(option) => option.value"
        class="dropdown-style"
        :clearable="false"
      />
      <label class="mt-2">{{ $t("maintenance_plan.maintenance_type") }}</label>
      <select
        class="form-control form-border"
        v-model="filterOptions.maintenance_type"
      >
        <option :value="null">ทั้งหมด</option>
        <option value="MA">MA</option>
        <option value="Repair">Repair</option>
        <option value="Other">Other</option>
      </select>
    </b-modal>
    <b-modal
      id="create-modal"
      size="sm"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row>
        <b-col class="text-end" cols="2"> </b-col>
        <b-col class="text-center" cols="8">
          <h5 class="my-3">
            {{ $t("maintenance_plan.maintenance_detail") }}
          </h5>
        </b-col>
        <b-col class="text-end" cols="2">
          <button
            class="close_modal text-light border-none"
            @click="$bvModal.hide('create-modal')"
          >
            <b-icon-x scale="1.5" />
          </button>
        </b-col>
      </b-row>
      <form class="create-form" @submit.prevent="createMaintenance">
        <label>{{ $t("maintenance_plan.maintenance_date") }}</label>
        <date-picker
          class="inline-block h-full col"
          v-model="maintenanceDate"
          locale="en"
          is-required
        >
          <template v-slot="{ inputValue, togglePopover }">
            <div class="row">
              <div class="col-12">
                <input
                  style="cursor: pointer;"
                  :value="inputValue | formatDate"
                  class="form-control px-20"
                  readonly
                  @click="togglePopover()"
                />
              </div>
            </div>
          </template>
        </date-picker>
        <label for="">{{ $t("maintenance_plan.vendor") }}</label>
        <select class="form-control w-100" required v-model="vendorForCreate">
          <option :value="null" disabled>กรุณาเลือก</option>
          <option
            v-for="vendor in assetOptions.optionVendor"
            :key="vendor.vendor_id"
            :value="vendor.vendor_id"
            >{{ vendor.name }}</option
          >
        </select>
        <label>{{ $t("maintenance_plan.maintenance_type") }}</label>
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            required
            v-model="maintenanceType"
            :aria-describedby="ariaDescribedby"
            class="d-flex justify-content-between"
          >
            <b-form-radio class="radio-label" value="MA">MA</b-form-radio>
            <b-form-radio class="radio-label" value="Repair"
              >Repair</b-form-radio
            >
            <b-form-radio class="radio-label" value="Other">Other</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <label>{{ $t("maintenance_plan.maintenance_detail") }}</label>
        <b-form-textarea
          id="textarea"
          v-model="maintenanceDetail"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <label>{{ $t("maintenance_plan.maintenance_price") }}</label>
        <vue-numeric
          required
          class="w-100 form-control"
          separator=","
          decimal-separator="."
          :precision="2"
          v-model="maintenancePrice"
        ></vue-numeric>
        <label>{{ $t("maintenance_plan.ref_1") }}</label>
        <input type="text" class="form-control" v-model="ref1" />
        <label>{{ $t("maintenance_plan.ref_2") }}</label>
        <input type="text" class="form-control" v-model="ref2" />
        <div class="mx-auto my-3" style="width: fit-content;">
          <button type="button" class="btn cancel" @click="cancelCreate">
            {{ $t("btn.canc") }}
          </button>
          <button type="submit" class="btn">
            {{ $t("btn.save") }}
          </button>
        </div>
      </form>
    </b-modal>
    <b-modal
      id="cancel-modal"
      :cancel-title="$t('btn.canc')"
      centered
      hide-header-close
      hide-header
      hide-footer
      :ok-title="$t('btn.conf')"
    >
      <h5 class="text-center mb-3">{{ $t("dialog.conf_canc_maintenance") }}</h5>
      <div class="d-flex justify-content-center w-100">
        <button class="btn cancel" @click="$bvModal.hide('cancel-modal')">
          {{ $t("btn.canc") }}
        </button>
        <button class="btn confirm-button" @click="cancelMaintenance">
          {{ $t("btn.conf") }}
        </button>
      </div>
    </b-modal>
    <div class="responsive-bar">
      <Footer></Footer>
      <div>
        <div class="back-icon">
          <img
            src="../../assets/previous.svg"
            width="30"
            @click="$router.back()"
          />
        </div>
        <div class="header-text">
          <div class="topic" style="">
            <h1>{{ $t("maintenance_plan.maintenance_history") }}</h1>
            <h2>{{ $t("asset.as_num") }}: {{ idFixedAsset }}</h2>
          </div>
        </div>
        <div class="py-3">
          <div class="container-fluid">
            <div class="text-start">
              <p>
                {{ $t("maintenance_plan.maintenance_amount") }} :
                {{ repairAmount ? repairAmount.toLocaleString() : "0" }}
                {{ $t("maintenance_plan.round") }}
              </p>
              <p>
                {{ $t("maintenance_plan.total_maintenance_price") }} :
                {{ numberComma(repairCost) }}
              </p>
            </div>
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center flex-wrap">
                <button
                  v-if="!isNativeApp"
                  @click="$bvModal.show('export-modal')"
                  class="m-1 outline-button table"
                  style="height: 45px"
                >
                  <div
                    style="display: flex; align-items: center; justify-content: space-evenly;"
                  >
                    <b-icon-upload font-scale="1.5" style="margin-right: 5px" />
                    {{ $t("btn.ex_file") }}
                  </div>
                </button>
                <button
                  class="m-1 outline-button table"
                  style="height: 45px"
                  @click="$bvModal.show('create-modal')"
                >
                  <div
                    style="display: flex; align-items: center; justify-content: space-evenly;"
                  >
                    <b-icon-plus font-scale="1.5" style="margin-right: 5px" />
                    {{ $t("maintenance_plan.add_maintenance_round") }}
                  </div>
                </button>
                <button
                  v-if="allowCancel"
                  class="m-1 outline-button-red"
                  style="height: 45px"
                  @click="$bvModal.show('cancel-modal')"
                >
                  <div
                    style="display: flex; align-items: center; justify-content: space-evenly;"
                  >
                    {{ $t("btn.canc") }}
                  </div>
                </button>
              </div>
              <div class="d-flex my-2">
                <span
                  class="filter-box"
                  style="border-radius: 10px; display: flex; padding-left: 1rem; margin-right: 1rem;"
                >
                  <img src="@/assets/search-white.svg" width="20px" />
                  <input
                    type="text"
                    class="form-control filter-box input-custom"
                    style="width: 250px min-width: 250px"
                    v-debounce:700ms="searchByText"
                    :placeholder="$t('home.search')"
                    v-model="searchText"
                  />
                </span>

                <!-- Filter Button -->
                <button
                  class="filter"
                  style="border: none"
                  type="button"
                  id="button-addon2"
                  @click="$bvModal.show('filter-modal')"
                >
                  <img src="@/assets/filter-white.svg" />
                </button>
              </div>
            </div>
            <MaintenanceSummaryTable
              class="my-4"
              :page="currentPage"
              :perPage="perPage"
              :assetData="repairData"
              :otherColumn="otherColumn"
              @set-watcher="setWatcher"
              ref="SummaryTable"
            />
            <div class="d-flex justify-content-center mt-3">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
        <div class="line-break"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Footer from "@/components/Footer.vue";
import MaintenanceSummaryTable from "@/components/CustomTable/MaintenancePlanSummaryTable.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
import { mapGetters } from "vuex";

import axios from "axios";
import { baseUrl } from "../../util/backend.js";
import { authHeader } from "../../store/actions";

export default {
  components: { Footer, MaintenanceSummaryTable, DatePicker, Loading },
  data() {
    return {
      isLoading: true,
      vendorOptions: [],
      vendorForCreate: null,
      idFixedAsset: "",
      page: null,
      id_company: null,
      repairData: [],
      repairAmount: 0,
      repairCost: 0,
      otherColumn: [],
      maintenanceDate: new Date(),
      maintenanceType: "MA",
      maintenanceDetail: "",
      maintenancePrice: 0,
      ref1: "",
      ref2: "",
      allowCancel: false,
      searchText: "",
      assetOptions: {},
      filterOptions: {
        text: "",
        start_date: null,
        end_date: null,
        vendor: null,
        maintenance_type: null,
      },
      currentPage: 1,
      totalRows: 1,
      perPage: 100,
    };
  },
  filters: {
    formatDate(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY");
    },
  },
  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
      getUserDetail: "getUserDetail",
      permissionStatus: "permissionStatus",
    }),
    optionsVendor() {
      const opt =
        this.vendorOptions?.map((v) => {
          return {
            label: v,
            value: v,
          };
        }) || [];
      return [{ label: "ทั้งหมด", value: null }, ...opt];
    },
  },
  watch: {
    currentPage() {
      this.loadData();
    },
    "filterOptions.start_date"() {
      if (this.filterOptions.start_date > this.filterOptions.end_date) {
        this.filterOptions.end_date = this.filterOptions.start_date;
      }
    },
  },
  methods: {
    moment,
    numberComma(num) {
      let number = num;
      if (number) {
        number = number.toFixed(2);
      }
      return number
        ? number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        : "0";
    },
    async handleclear() {
      // this.countingStatus = "ทั้งหมด";
      this.currentPage = 1;
      this.searchText = "";
      this.filterOptions = {
        text: "",
        start_date: null,
        end_date: null,
        vendor: null,
        maintenance_type: null,
      };
      this.loadData();
    },
    handleFilter() {
      this.loadData();
    },
    setWatcher() {
      this.$watch(
        () => {
          return this.$refs.SummaryTable.isSelectedAsset;
        },
        (val) => {
          this.allowCancel = val;
        }
      );
    },
    async createMaintenance() {
      try {
        const res = await axios.post(
          `${baseUrl()}maPlan/createRepairReport`,
          {
            id_asset: this.$route.params.assetId,
            repair_type: this.maintenanceType,
            repair_cost: this.maintenancePrice,
            repair_date: moment(this.maintenanceDate).toISOString(),
            detail: this.maintenanceDetail,
            ref1: this.ref1,
            ref2: this.ref2,
            id_company: this.id_company,
            vendor_id: this.vendorForCreate,
          },
          authHeader()
        );
        this.loadData();
        this.$bvModal.hide("create-modal");
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err);
        }
      }
    },

    cancelCreate() {
      this.maintenanceDate = new Date();
      this.maintenanceType = "MA";
      this.maintenanceDetail = "";
      this.maintenancePrice = 0;
      this.ref1 = "";
      this.ref2 = "";
      this.$bvModal.hide("create-modal");
    },
    async cancelMaintenance() {
      const row = this.$refs.SummaryTable.getSelectedRowData();
      const cancelArr = row.map((asset) => asset.asset_repair_report_id);
      try {
        await this.$store.dispatch("cancelRepairReport", {
          id_company: this.id_company,
          asset_repair_report_id_list: cancelArr,
        });
      } catch (err) {
        alert(err);
      }
      this.$bvModal.hide("cancel-modal");
      this.loadData();
    },
    async searchByText() {
      this.loadData();
    },
    async onExport(isMail) {
      this.isLoading = true;
      const row = this.$refs.SummaryTable.getSelectedRowData();
      const selectedArr = row.map((asset) => asset.asset_repair_report_id);
      await axios
        .post(
          `${baseUrl()}maPlan/exportReportRepair/${this.id_company}`,
          {
            id_user: this.getUserDetail.id_user,
            report_list: selectedArr.length > 0 ? selectedArr : null,
            id_asset: this.$route.params.assetId,
          },
          { ...authHeader(), responseType: "blob" }
        )
        .then((response) => {
          if (!isMail) {
            const blob = new Blob([response.data], {
              type: "application/vnd.ms-excel",
            });
            const elink = document.createElement("a");
            elink.download = this.$t("maintenance_plan.summary") + ".xlsx";
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.isLoading = false;
      this.$bvModal.hide("export-modal");
    },

    async loadData() {
      this.isLoading = true;
      try {
        const res = await axios.get(
          `${baseUrl()}maPlan/getAllRepairReportById_asset`,
          {
            params: {
              id_company: this.id_company,
              id_asset: this.$route.params.assetId,
              show_cancel: true,
              page: this.currentPage,
              limit: this.perPage,
              text: this.searchText || null,
              repair_start_time:
                this.filterOptions.start_date &&
                moment(this.filterOptions.start_date).format("YYYY-MM-DD"),
              repair_end_time:
                this.filterOptions.end_date &&
                moment(this.filterOptions.end_date).format("YYYY-MM-DD"),
              vendor_name: this.filterOptions.vendor,
              repair_type: this.filterOptions.maintenance_type,
            },
            ...authHeader(),
          }
        );
        this.idFixedAsset = res.data.id_fixed_asset;
        this.totalRows = res.data.count_pagination;
        this.repairAmount = res.data.count;
        this.repairCost = res.data.cost;
        this.repairData = res.data.data;
        this.otherColumn = res.data.other_column;
        this.vendorOptions = res.data.optionsVendor;
      } catch (err) {
        console.log(err);
      }
      this.allowCancel = false;
      this.isLoading = false;
    },
    async loadAssetOptions() {
      try {
        const res = await axios.get(
          `${baseUrl()}asset/get/option/${this.id_company}`,
          authHeader()
        );
        this.assetOptions = res.data.data;
      } catch (err) {
        console.log(err);
      }
    },
  },

  async mounted() {
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.loadAssetOptions();
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 14px;
}

.input-custom {
  height: 45px;
  border-radius: 10px 10px 10px 10px;
  margin: 0 5px;
}

.header-text {
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 14px;
  }
}

.gradient-text {
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.topic {
  padding-top: 2.7rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

.fill-button {
  background: transparent linear-gradient(180deg, #007bff 0%, #003e80 100%) 0%
    0% no-repeat padding-box;
}
.outline-button {
  width: 160px;
  height: auto;
  padding: 5px;
  border-radius: 10px;
  color: $color-active-blue;
  box-shadow: $shadow-default;
  border: solid 2px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(180deg, #007afe 0%, #013f81 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;

  &.table {
    font-size: 14px;
    height: 100%;
    width: auto;
    margin: 0;
    padding: 5px 10px;
  }
}

.outline-button-red {
  width: 160px;
  height: auto;
  padding: 5px 20px;
  border-radius: 10px;
  color: red;
  background: white;
  border: 2px solid red;
  font-size: 14px;
  height: 100%;
  width: auto;
  margin: 0;
}

.filter {
  border-radius: 10px 10px 10px 10px !important;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  margin: 0 !important;

  img {
    width: 40px;
    padding: 0.3rem;
  }
}

.download-file {
  padding: 0 10px 0 10px;
  color: white;
  border-radius: 8px;
  border: none;
  width: 130px;
  height: 34px;
  font-size: 14px;
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#007afe),
      to(#013f81)
    ) 0% 0% no-repeat padding-box;
}
.email-me {
  background: transparent;
  color: #007afe;
  border: solid 2px;
  border-radius: 8px;
  width: 130px;
  height: 34px;
  font-size: 14px;
  margin-right: 10px;
}

.dropdown-style {
  background-color: white;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}

.dropdown-style ::v-deep .vs__search {
  background-color: white !important;
}

#create-modal {
  .btn {
    min-width: 100px;
  }
}

button.cancel {
  margin-right: 10px;
  // margin: 15px 10px 20px 30px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: #7c7c7c;
}

.radio-label ::v-deep .custom-control-label {
  margin: 10px;
}

.create-form {
  label,
  input {
    margin: 3px 0;
  }
  input[type="tel"] {
    text-align: right;
  }
}
</style>
